import { GlobalHoverService } from 'o365-vue-services';
import PersonHover from 'system.libraries.vue.components.PersonHover.vue';

export default {
    mounted(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        service.bindElement(el, {
            component: PersonHover,
            props: {
                personId: binding.value,
            },
        })
    },
    updated(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        const props = service.getPropsFromEl(el)
        if (props && props.personId !== binding.value) {
            props.personId = binding.value;
        }
    },
    unmounted(el: HTMLElement, _binding: any) {
        const service = GlobalHoverService.getService();
        service.unbindElement(el);

    }
};